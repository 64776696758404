import { Box, Heading } from '@chakra-ui/react';

import { useEnvironmentFrame } from '~sf/utils/hooks/useEnvironmentFrame';

export const EnvironmentFrameBox = () => {
  const { hasEnvironmentFrame, frameColor, envName, borderWidth } = useEnvironmentFrame();

  if (!hasEnvironmentFrame) {
    return null;
  }
  return (
    <Box
      border={`${borderWidth}px solid ${frameColor}`}
      position="fixed"
      top="0"
      height="100vh"
      width="100vw"
      zIndex={10}
      pointerEvents="none"
    >
      <Heading
        fontSize="18"
        mt={`-${borderWidth + 2}px`}
        px={2}
        mx="auto"
        backgroundColor="white"
        color={frameColor}
        width="fit-content"
      >
        <strong>&gt; {envName.toUpperCase()} &lt;</strong>
      </Heading>
    </Box>
  );
};
