import { Entitlements } from "~sf/models";
import filterEmptyValues from "~sf/utils/filterEmptyValues";
import isNullOrUndefined from "~sf/utils/isNullOrUndefined";

import { EntitlementOptions as EntitlementAction, entitlementsMapping } from "./entitlementsMapping";

export type EntitlementActionMap = {
  [key in EntitlementAction]: boolean;
};

// Initialize the entitlementMap to an object of all possible actions as false
const initialEntries: [EntitlementAction, boolean][] = Object.values(entitlementsMapping)
  .filter(filterEmptyValues)
  .map((action) => [action, false]);

export const DEFAULT_ENTITLEMENT_MAP = Object.fromEntries(initialEntries) as EntitlementActionMap;

export const generateEntitlementMap = (entitlements: Entitlements[]) => {
  const mutable_entitlementMap = { ...DEFAULT_ENTITLEMENT_MAP };

  // Set the related action for entitlement that comes from the backend as true
  // In cases where multiple entitlements share an action, it will be true if any is set
  entitlements.forEach((entitlement) => {
    const action = entitlementsMapping[entitlement];

    if (!isNullOrUndefined(action)) {
      mutable_entitlementMap[action] = true;
    }
  });

  return mutable_entitlementMap;
};
