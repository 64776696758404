import { importMetaEnv } from '~sf/constants';

import useProfile from './useProfile';

export const useEnvironmentFrame = () => {
  const profile = useProfile();

  const hasEnvironmentFrame =
    importMetaEnv.VITE_ENV_FRAME_COLOR && importMetaEnv.VITE_ENV_NAME && profile?.role === 'admin';

  return {
    hasEnvironmentFrame,
    frameColor: importMetaEnv.VITE_ENV_FRAME_COLOR,
    envName: importMetaEnv.VITE_ENV_NAME,
    borderWidth: 12,
  };
};
